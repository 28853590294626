import React, { useCallback, useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import cx from 'classnames';
import { Box, Container, Hidden, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import {
  Layout,
  ButtonOutboundLink,
  BadgesByDevice,
  PinkBadge,
} from 'src/components';
import Slider from 'react-slick';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/Newlife/SectionCampaignFooter';
import PaidyLogoSvg from 'src/images/paidy-logo.svg';
import BannerImg from 'src/images/campaign-202303/banner.png';
import BannerSpImg from 'src/images/campaign-202303/banner-sp.png';
import ScrollImg from 'src/images/campaign-202303/scroll.png';
import Video1ThumbnailVideo from 'src/images/campaign-202303/video-1-thumbnail.mp4';
import Video2ThumbnailImg from 'src/images/campaign-202303/video-2-thumbnail.png';
import Video3ThumbnailImg from 'src/images/campaign-202303/video-3-thumbnail.png';
import INILogoSvg from 'src/images/campaign-202303/INI-logo.svg';
import INIPhotoImg from 'src/images/campaign-202303/INI-photo.png';
import Step1Img from 'src/images/campaign-202303/step-1.png';
import Step2Img from 'src/images/campaign-202303/step-2.png';
import Step3Img from 'src/images/campaign-202303/step-3.png';
import Step4Img from 'src/images/campaign-202303/step-4.png';
import QrImg from 'src/images/campaign-202303/qr.png';
import TwitterGreySvg from 'src/images/campaign-202303/icon-x-black.svg';
import TwitterWhiteSvg from 'src/images/campaign-202303/icon-x-white.svg';
import PlayVideoBtnImg from 'src/images/campaign-202303/play-video-btn.png';
import PaidyLogoWhiteSvg from 'src/images/campaign-202303/paidy-logo-white.svg';
import BorderLinearGradientImg from 'src/images/campaign-202303/border-linear-gradient.png';
import SpecialMovieThumbnail2Img from 'src/images/campaign-202303/special-movie-thumbnail-2.png';
import SpecialMovieThumbnail1Video from 'src/images/campaign-202303/special-movie-video-thumbnail-1.mp4';
import styles from 'src/styles/pages/campaign_202303.module.scss';
import { SITE_METADATA, PAIDY_HOST } from 'src/constants';
import OgpImg from 'src/images/campaign-202303/202303_TCMP_OGP_v1.jpg';
import SectionCampaign from './campaign_202303/SectionCampaign';
import SpecialContents from './campaign_202303/SpecialContents';
import TitleSection from './campaign_202303/TitleSection';

const SEOProps = {
  ogImage: OgpImg,
  twitterImage: `${PAIDY_HOST}/tvcm_ogp_v2.jpg`,
  title: SITE_METADATA.campaign202303.title,
  description: SITE_METADATA.campaign202303.description,
};

const ITEMS = [
  {
    ImgComponent: () => (
      <img alt="step-1" src={Step1Img} width={250} height={362} />
    ),
    key: '1',
  },
  {
    ImgComponent: () => (
      <img alt="step-2" src={Step2Img} width={250} height={362} />
    ),
    key: '2',
  },
  {
    ImgComponent: () => (
      <img alt="step-3" src={Step3Img} width={250} height={362} />
    ),
    key: '3',
  },
  {
    ImgComponent: () => (
      <img alt="step-4" src={Step4Img} width={250} height={362} />
    ),
    key: '4',
  },
];

// Auto play
const VIDEO_1_EMBEDDED =
  'https://www.youtube.com/embed/OQUpxVVfEP0?&autoplay=1';
const VIDEO_2_EMBEDDED =
  'https://www.youtube.com/embed/uwlR_oeNlfc?&autoplay=1';
const VIDEO_3_EMBEDDED =
  'https://www.youtube.com/embed/_-OfUshD07k?&autoplay=1';
const SPECIAL_MOVIE_EMBEDDED_1 =
  'https://www.youtube.com/embed/W_8OlY7wHHU?&autoplay=1';
const SPECIAL_MOVIE_EMBEDDED_2 =
  'https://www.youtube.com/embed/L1lfvFGvzWc?&autoplay=1';

const SLIDER_SETTINGS = {
  className: 'slider variable-width',
  slidesToShow: 4,
  swipe: true,
  dots: true,
  variableWidth: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const TWITTER_URL_SHARE =
  'https://twitter.com/intent/tweet?url=https%3A%2F%2Fpaidy.com%2Fcampaign_202303';

const HEADER_MENU = [
  {
    id: 'section-01',
    number: '01',
    text: 'MOVIES',
    to: '#section-01',
  },
  {
    id: 'section-02',
    number: '02',
    text: 'SPECIAL MOVIE',
    to: '#section-02',
  },
  {
    id: 'section-03',
    number: '03',
    text: 'SPECIAL CONTENTS',
    to: '#section-03',
    newText: undefined,
    description: 'メンバーたちのコメントを画像をタップしてチェック！',
    isNew: false,
  },
  {
    id: 'section-04',
    number: '04',
    text: 'CAMPAIGN',
    to: '#section-04',
  },
  {
    id: 'section-05',
    number: '05',
    text: "WHAT'S Paidy",
    to: '#section-05',
  },
];

export default function Campaign202303() {
  const [playVideo, setPlayVideo] = useState(false);
  const [playUrl, setPlayUrl] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const onClickMenu = () => setTimeout(() => setMenuOpen(!menuOpen), 100);

  const onCloseVideoPlayer = useCallback(() => {
    setPlayUrl('');
    setPlayVideo(false);
  }, []);

  const onClickPlayVideo = useCallback(
    (url) => () => {
      setPlayUrl(url);
      setPlayVideo(true);
    },
    []
  );

  useEffect(() => {
    const { hash } = location;

    if (hash.includes('section') && window) {
      setTimeout(() => {
        window.location.href = hash;
      }, 500);
    }
  }, []);

  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      {playVideo && (
        <div className={styles.videoPlayer}>
          <button className={styles.btnClose} onClick={onCloseVideoPlayer}>
            X
          </button>
          <iframe
            src={playUrl}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            className={styles.iframe}
          />
        </div>
      )}

      <Hidden xsDown>
        <header className={styles.header}>
          <Container className={styles.container}>
            <nav className={styles.nav}>
              <Link to="/">
                <img alt="logo" src={PaidyLogoSvg} width={100} height={40} />
              </Link>
              <div className={styles.right}>
                {HEADER_MENU.map((menu) => (
                  <Link
                    key={menu.number}
                    className={styles.link}
                    to={menu.to}
                    onClick={onClickMenu}
                  >
                    <span className={styles.number}>{menu.number}</span>
                    <span className={styles.span}>{menu.text}</span>
                    <Box display="inline-block" ml={1}>
                      {menu.isNew && <PinkBadge />}
                    </Box>
                  </Link>
                ))}
              </div>
            </nav>
          </Container>
        </header>
      </Hidden>

      <Hidden smUp>
        {!menuOpen ? (
          <header className={styles.headerMobile}>
            <nav className={styles.nav}>
              <Link to="/">
                <img alt="logo" src={PaidyLogoSvg} width={100} height={40} />
              </Link>
              <button className={styles.menu} onClick={onClickMenu} />
            </nav>
          </header>
        ) : (
          <header className={styles.headerMobileActive}>
            <nav className={styles.nav}>
              <Link to="/">
                <img
                  alt="logo"
                  src={PaidyLogoWhiteSvg}
                  width={100}
                  height={28}
                />
              </Link>
              <button className={styles.menu} onClick={onClickMenu} />
            </nav>
            <ul className={styles.links}>
              {HEADER_MENU.map((menu) => (
                <Link
                  key={menu.number}
                  to={menu.to}
                  onClick={onClickMenu}
                  className={styles.link}
                >
                  <Box display="flex" alignItems="center">
                    <p className={styles.number}>{menu.number}</p>
                    {menu.isNew && (
                      <Box ml={1}>
                        <PinkBadge />
                      </Box>
                    )}
                  </Box>
                  <p className={styles.text}>{menu.text}</p>
                  <img
                    alt="border-linear-gradient"
                    src={BorderLinearGradientImg}
                    className={styles.linearBorder}
                  />
                </Link>
              ))}
            </ul>
            <div className={styles.bottom}>
              <OutboundLink
                className={styles.shareBtn}
                href={TWITTER_URL_SHARE}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>SHARE</span>
                <img
                  alt="twitter"
                  src={TwitterWhiteSvg}
                  width={20}
                  height={16}
                />
              </OutboundLink>
              <BadgesByDevice />
            </div>
          </header>
        )}
      </Hidden>

      <section className={styles.sectionBanner}>
        <Container className={styles.container}>
          <Hidden xsDown>
            <img
              className={styles.banner}
              alt="banner"
              src={BannerImg}
              height={400}
            />
          </Hidden>
          <Hidden smUp>
            <img className={styles.banner} alt="banner" src={BannerSpImg} />
          </Hidden>
          <div className={styles.bottom}>
            <img
              className={styles.scroll}
              alt="scroll"
              src={ScrollImg}
              width={17}
              height={120}
            />
            <BadgesByDevice className={styles.badges} />
            <p className={styles.text}>
              人の数だけ、その人だけのスタイルがある。
              あなたにぴったりの支払い方が見つかれば、
              お買い物はもっと自由になるはず。
            </p>
            <h1 className={styles.question}>そのお買い物、どう支払う？</h1>
          </div>
        </Container>
      </section>

      <section className={styles.section01}>
        <Box id={HEADER_MENU[0].id} pt={9} />

        <TitleSection
          number={HEADER_MENU[0].number}
          text={HEADER_MENU[0].text}
        />
        <button
          onClick={onClickPlayVideo(VIDEO_1_EMBEDDED)}
          className={cx(styles.videoContainer, styles.videoContainer1)}
        >
          <video
            autoPlay
            muted
            width={660}
            height={372}
            className={styles.video1}
            loop
            playsInline
          >
            <source src={Video1ThumbnailVideo} type="video/mp4" />
            <track kind="captions" />
          </video>
          <div className={styles.overlay}>
            <div className={styles.shadow}>
              <p className={styles.text}>｢あなたに自由なあと払い｣ 篇</p>
              <img
                alt="play-video-btn"
                src={PlayVideoBtnImg}
                width={96}
                height={96}
                className={styles.btn}
              />
            </div>
          </div>
        </button>
        <div className={styles.twoVideos}>
          <button
            onClick={onClickPlayVideo(VIDEO_2_EMBEDDED)}
            className={styles.videoContainer}
          >
            <img
              alt="video-2"
              src={Video2ThumbnailImg}
              width={326}
              height={180}
              className={styles.thumbnail}
            />
            <div className={styles.overlay}>
              <div className={styles.shadow}>
                <p className={cx(styles.text, styles.textSmall)}>
                  ｢スマホだけで買えて
                  <br />
                  翌月お支払い｣ 篇
                </p>
                <img
                  alt="play-video-btn"
                  src={PlayVideoBtnImg}
                  width={64}
                  height={64}
                  className={styles.btnSmall}
                />
              </div>
            </div>
          </button>
          <button
            onClick={onClickPlayVideo(VIDEO_3_EMBEDDED)}
            className={styles.videoContainer}
          >
            <img
              alt="video-3"
              src={Video3ThumbnailImg}
              width={326}
              height={180}
              className={styles.thumbnail}
            />
            <div className={styles.overlay}>
              <div className={styles.shadow}>
                <p className={cx(styles.text, styles.textSmall)}>
                  ｢手数料無料の3回
                  <br />
                  あと払い｣ 篇
                </p>
                <img
                  alt="play-video-btn"
                  src={PlayVideoBtnImg}
                  width={64}
                  height={64}
                  className={styles.btnSmall}
                />
              </div>
            </div>
          </button>
        </div>

        <div className={styles.card}>
          <div className={styles.left}>
            <div className={styles.top}>
              <p className={styles.cast}>CAST</p>
              <img
                alt="INI-logo"
                src={INILogoSvg}
                width={103}
                height={120}
                className={styles.logo}
              />
            </div>

            <Hidden smUp>
              <img alt="INI" src={INIPhotoImg} className={styles.photo} />
            </Hidden>

            <div className={styles.bottom}>
              <h4 className={styles.title}>
                11人の僕たち(私:I)があなた(I)と
                <br />
                繋がり合う(Network)
              </h4>
              <p className={styles.text}>
                2021年、「PRODUCE 101 JAPAN SEASON2」で
                “国民プロデューサー”によって選ばれた11人組グローバルボーイズグループ。
              </p>
            </div>
          </div>
          <Hidden xsDown>
            <img alt="INI" src={INIPhotoImg} width={609} height={406} />
          </Hidden>
        </div>
      </section>

      <section id={HEADER_MENU[1].id} className={styles.sectionSpecialMovie}>
        <TitleSection
          number={HEADER_MENU[1].number}
          text={HEADER_MENU[1].text}
          description={HEADER_MENU[1].description}
          newText={HEADER_MENU[1].newText}
        />
        <Box mt={4} />
        <button
          onClick={onClickPlayVideo(SPECIAL_MOVIE_EMBEDDED_1)}
          className={cx(styles.videoContainer, styles.videoContainer1)}
        >
          <video
            autoPlay
            muted
            width={662}
            height={372}
            className={styles.video1}
            loop
            playsInline
          >
            <source src={SpecialMovieThumbnail1Video} type="video/mp4" />
            <track kind="captions" />
          </video>
          <div className={styles.overlay}>
            <div className={styles.shadow}>
              <Typography className={styles.text}>インタビュー動画</Typography>
              <img
                alt="play-video-btn"
                src={PlayVideoBtnImg}
                width={96}
                height={96}
                className={styles.btn}
              />
            </div>
          </div>
        </button>

        <button
          onClick={onClickPlayVideo(SPECIAL_MOVIE_EMBEDDED_2)}
          className={cx(styles.videoContainer, styles.videoContainer1)}
        >
          <img
            width={662}
            height={372}
            alt="special-movie-2"
            className={styles.video1}
            src={SpecialMovieThumbnail2Img}
          />

          <div className={styles.overlay}>
            <div className={styles.shadow}>
              <Typography className={styles.text}>メイキング動画</Typography>
              <img
                alt="play-video-btn"
                src={PlayVideoBtnImg}
                width={96}
                height={96}
                className={styles.btn}
              />
            </div>
          </div>
        </button>
      </section>

      <SpecialContents
        id={HEADER_MENU[2].id}
        number={HEADER_MENU[2].number}
        text={HEADER_MENU[2].text}
        description={HEADER_MENU[2].description}
        newText={HEADER_MENU[2].newText}
      />

      <SectionCampaign
        id={HEADER_MENU[3].id}
        number={HEADER_MENU[3].number}
        text={HEADER_MENU[3].text}
      />

      <section className={styles.section02} id={HEADER_MENU[4].id}>
        <TitleSection
          number={HEADER_MENU[4].number}
          text={HEADER_MENU[4].text}
        />
        <p className={styles.description}>
          AmazonやSHEINのほか、Visaマークのあるお店ならどこでも、スマホだけで今すぐお買い物ができるあと払いサービスです。お支払いは翌月でOK。さらに、リアルカードを発行すると、Visaマークのある街のお店でもペイディが利用可能に。{' '}
        </p>
        <Slider {...SLIDER_SETTINGS} className={styles.slider}>
          {ITEMS.map((item) => (
            <div key={item.key} style={{ width: 300 }}>
              {item.ImgComponent()}
            </div>
          ))}
        </Slider>
        <ButtonOutboundLink
          className={styles.btn}
          href="https://paidy.com/shop"
          target="_blank"
          rel="noopener noreferrer"
        >
          使えるお店を探す
        </ButtonOutboundLink>
      </section>

      <section className={styles.sectionMultipayBanner}>
        <Box
          display="flex"
          gridGap={32}
          justifyContent="center"
          flexWrap="wrap"
        >
          <Box>
            <OutboundLink
              href="https://paidy.com/npay/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                alt="card-1"
                width={311}
                height={420}
                src={require('src/images/campaign-202303/card-1.png')}
              />
            </OutboundLink>
            <p className={styles.text}>*口座振替・銀行振込のみ無料</p>
          </Box>
          <Box>
            <OutboundLink
              href="https://paidy.com/paidycard/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                alt="card-2"
                width={311}
                height={420}
                src={require('src/images/campaign-202303/card-2.png')}
              />
            </OutboundLink>
          </Box>
        </Box>
      </section>

      <section className={styles.sectionQR}>
        <Hidden xsDown>
          <img alt="qr" src={QrImg} width={92} height={92} />
        </Hidden>
        <div>
          <Hidden xsDown>
            <h3 className={styles.textBadges}>アプリダウンロードはこちら</h3>
          </Hidden>
          <BadgesByDevice />
        </div>
        <OutboundLink
          className={styles.shareBtn}
          href={TWITTER_URL_SHARE}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>SHARE</span>
          <img alt="twitter" src={TwitterGreySvg} width={20} height={20} />
        </OutboundLink>
      </section>

      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
}
