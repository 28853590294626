import React from 'react';
import { Box } from '@material-ui/core';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { PAIDY_HOST } from 'src/constants';
import Banner2Img from 'src/images/campaign-202303/banner-amazon.png';
import BannerImg from 'src/images/campaign-202303/banner-general-cashback-202402.png';
import TitleSection from './TitleSection';
import styles from './SectionCampaign.module.scss';

const CAMPAIGNS = [
  {
    href: `${PAIDY_HOST}/campaign/general_cashback_202402/`,
    src: BannerImg,
    alt: 'general-cashback-202402',
  },
  {
    href: 'https://www.amazon.co.jp/b?node=8215625051',
    src: Banner2Img,
    alt: 'campaign_2',
  },
];

export default function SectionCampaign({ id, number, text }) {
  return (
    <section className={styles.section} id={id}>
      <TitleSection number={number} text={text} />
      <p className={styles.text}>
        お得なキャンペーンを開催中。このチャンスに、ぜ
        <br />
        ひペイディでお買い物をお楽しみください。
      </p>
      <Box className={styles.container}>
        {CAMPAIGNS.map((campaign) => (
          <OutboundLink
            href={campaign.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={campaign.src}
              alt={campaign.alt}
              width={311}
              height={336}
            />
          </OutboundLink>
        ))}
      </Box>
    </section>
  );
}
